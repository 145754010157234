import React from "react"
import bgImage from "../assets/mccalla-coaching-header-background.webp"
import mike from "../assets/Michael-McCalla-cycling-as-team.webp"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import PageHeader from "../components/PageHeader"
import { Container } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import CtaContact from "../components/CTAContact"
import Grid from "@material-ui/core/Grid"

const IndexPage = () => (
  <>
    <SEO title="About Me"/>
    <PageHeader backgroundImage={bgImage} backgroundImageHeight={30} backgroundTextTopPosition={"50%"}>
      <Typography variant="h1">About Michael</Typography>
    </PageHeader>
    <Container>
      <Box marginTop={8}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={7} md={8}>
            <Typography variant="body1" gutterBottom>
              About Michael: A lifelong student of the sport, Michael McCalla is a former pro mountain biker with several
              National Championship podiums, and has represented the United States in the World Mountain Bike Championships
              both as a junior and as a professional.
            </Typography>
            <Typography variant="body1" gutterBottom>
              He has ridden and raced both mountain and road bikes around the world,
              although he is especially familiar with the Rocky Mountains and the Southwest. He has helped cyclists and
              multisport athletes of numerous age brackets and disciplines to victories and podiums at events at every level
              of amateur competition, including National and World Championships.
            </Typography>
            <Typography variant="body1" gutterBottom>
              He has contributed several technical articles to “Mountain Flyer” magazine, and has also been the director of several large cycling events and
              races including GFNY Santa Fe and the Santa Fe Century. His path as an athlete led him to address every aspect
              of the sport, extensively researching the physiological, tactical, psychological, technical, nutritional and
              mechanical demands of cycling and multisport endurance competition. He is committed to both short and long
              term improvements and to draw out the absolute peak performance from every one of his athletes.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Michael’s interest in music and education led him to study and teach vocal music as well, he received a
              Bachelor’s of Music Education from New Mexico State University and a Master of Voice Performance from the
              University of Colorado. His teaching experience includes teaching at all age levels from K-12 in public,
              private and charter schools in Santa Fe New Mexico. He enjoys a number of outdoor sports, including bike
              touring, backpacking, climbing, skiing and peak bagging. He has completed the bike-legal sections of the
              Colorado trail, and climbed 38 of the 67 peaks over 14,000 feet of the lower 48 states, as well as exploring
              the trails and roads of 31 US states and 11 countries. Michael is committed to the pursuit of sharing the best
              experiences in life with his friends, family and clients.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Box align="center">
              <img src={mike} alt="Michael McCalla cycling as a team" style={{ maxWidth: '100%'}}/>
            </Box>
            <Box marginTop={2} align="center">
              <Typography variant="h2">Michael McCalla</Typography>
              <Typography variant="h5" component="h3">Cyclists Coach</Typography>
            </Box>
            <CtaContact/>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
)

export default IndexPage
